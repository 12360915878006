export default () => {
  document.querySelectorAll(".copyright .year").forEach((el) => {
    el.innerHTML = new Date().getFullYear() + "";
  });

  // Stop body scrolling during popup
  const closeRadio = document.querySelector<HTMLInputElement>("#popup-close");
  if (closeRadio) {
    document.querySelectorAll<HTMLInputElement>("#popups input[name=popup]").forEach((radio) => {
      radio.addEventListener("change", () => {
        document.body.classList.toggle("overflow-hidden", !closeRadio.checked);
        // need to make sure the video is stopped
        if (closeRadio.checked) {
          document.querySelector("#video-popup-checkbox-content iframe")?.removeAttribute("src");
        }
      });
    });
  }

  // video popups
  document.querySelectorAll(".video-link").forEach((el) => {
    el.addEventListener("click", (e) => {
      const href = el.getAttribute("href");
      if (href != null) {
        document.querySelector("#video-popup-checkbox-content iframe")?.setAttribute("src", href);
      }
    });
  });

  // setup the rotating hero images
  const imgs = document.querySelectorAll(".rotating-images img");
  if (imgs && imgs.length > 0) {
    const imgsLen = imgs.length;
    const rotateImages = () => {
      // just make sure it's only happening when the tab has focus
      requestAnimationFrame(() => {
        // pause on current image before rotating
        setTimeout(() => {
          let currImg = -1;

          for (let x = 0; x < imgsLen; x++) {
            if (imgs[x].classList.contains("current")) {
              // found it
              currImg = x;
              break;
            }
          }
          imgs[currImg].classList.add("previous");
          imgs[currImg].classList.remove("current");
          if (currImg + 1 == imgsLen) {
            // do the first image
            imgs[0].classList.add("current");
          } else {
            // just the next image
            imgs[currImg + 1].classList.add("current");
          }
          setTimeout(() => {
            imgs[currImg].classList.remove("previous");
          }, 600);
          rotateImages();
        }, 4000);
      });
    };
    rotateImages();
  }
};
